import React,{useState,useEffect} from "react";
import "./user-modal.scss";

import { clone } from "../../../utils/clone";
import Modal from "../../common/modal/modal";
import CarouselHeader from "../../common/carousel/carousel-header";
import Carousel from "../../common/carousel/carousel";

// import Admin from './user-modal-slides/admin';
import Activity from './user-modal-slides/activity';
import Security from './user-modal-slides/security';
import History from './user-modal-slides/history';
import Profile from './user-modal-slides/profile';

const SlideEnum = {
  Profile: {Key: 1, Path: "profile", Display: "Profile"},
  Activity: {Key: 2, Path: "activity", Display: "Activity"},
  Security: {Key: 3, Path: "security", Display: "Security"},
  History: {Key: 4, Path: "history", Display: "History"},
  Roles: {Key: 5, Path: "roles", Display: "Roles"},
}

const UserModal = ({viewUser,show,handleClose,refresh,}) => {

  const [slide,setSlide] = useState(1);
  const [slidToArray,setSlidToArray] = useState([]);

  useEffect(() => {
    let newSlidToArray = slidToArray;
    if(newSlidToArray.indexOf(slide) === -1){
      newSlidToArray = clone(slidToArray);
      newSlidToArray.push(slide);
    }
    setSlidToArray(newSlidToArray);
  },[slide,slidToArray])


  return (
    <Modal 
      className="user-modal" 
      show={show} 
      handleClose={() => {handleClose()}}>
      <div>
        <CarouselHeader className="entry-options hlo" slideTo={slide} forceResize={show}>
          <div onClick={() => { setSlide(SlideEnum.Profile.Key);}}>{SlideEnum.Profile.Display}</div>
          <div onClick={() => { setSlide(SlideEnum.Activity.Key);}}>{SlideEnum.Activity.Display}</div>
          <div onClick={() => { setSlide(SlideEnum.Security.Key);}}>{SlideEnum.Security.Display}</div>
          <div onClick={() => { setSlide(SlideEnum.History.Key);}}>{SlideEnum.History.Display}</div>
          <div onClick={() => { setSlide(SlideEnum.Roles.Key);}}>{SlideEnum.Roles.Display}</div>
        </CarouselHeader>
        <Carousel slideTo={slide}>
          <div><Profile viewUser={viewUser} load={slidToArray.indexOf(SlideEnum.Profile.Key) !== -1} /></div>
          <div><Activity viewUser={viewUser} load={slidToArray.indexOf(SlideEnum.Activity.Key) !== -1} /></div>
          <div><Security viewUser={viewUser} load={slidToArray.indexOf(SlideEnum.Security.Key) !== -1} /></div>
          <div><History viewUser={viewUser} load={slidToArray.indexOf(SlideEnum.History.Key) !== -1} /></div>
          <div>Roles</div>
        </Carousel>
      </div>
    </Modal>
  );
};

export default UserModal;