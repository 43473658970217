import React from 'react';

import {ReactComponent as DashboardSvg} from '../../css/imgs/icon-dashboard.svg';
import {ReactComponent as PeopleSvg} from "../../css/imgs/icon-people.svg";
import {ReactComponent as PersonAdminSvg} from "../../css/imgs/icon-person-admin.svg";
import {ReactComponent as CustomerSupportSvg} from '../../css/imgs/icon-customer-support.svg';
import {ReactComponent as FeedbackSvg} from '../../css/imgs/icon-feedback.svg';
import {ReactComponent as EngineerSvg} from '../../css/imgs/icon-engineer.svg';
import {ReactComponent as QrCodeSvg} from '../../css/imgs/icon-qr-code-scan.svg';
import {ReactComponent as LikeSvg} from '../../css/imgs/icon-thumbs-up.svg';
import {ReactComponent as QuickReplySvg} from '../../css/imgs/icon-quick-reply.svg';
import {ReactComponent as ForumSvg} from '../../css/imgs/icon-forum.svg';
import {ReactComponent as TerminalSvg} from '../../css/imgs/icon-terminal.svg';
import {ReactComponent as SearchSvg} from '../../css/imgs/icon-seo.svg';
import {ReactComponent as SubscriptionSvg} from '../../css/imgs/icon-subscriptions.svg';

import AccountStatusEnum from '../../enums/account-status';

const naviRoutes = [
  // {
  //   "name": "dashboard-nav-item", 
  //   "display": "Dashboard", 
  //   "path": "/dashboard",
  //   "icon": <DashboardSvg/>,
  //   "minStatus": AccountStatusEnum.Staff,
  // },
  {
    "name": "site-users-nav-item", 
    "display": "Users (Site)", 
    "desc": "Site Database",
    "path": "/users-site",
    "icon": <PeopleSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "site-subscriptions-nav-item", 
    "display": "Subscriptions", 
    "desc": "Site Database",
    "path": "/subscriptions",
    "icon": <SubscriptionSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "ref-code-item", 
    "display": "Referrals", 
    "desc": "Site Database",
    "path": "/referrals",
    "icon": <QrCodeSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "like-item", 
    "display": "Likes", 
    "desc": "Site Database",
    "path": "/likes",
    "icon": <LikeSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "search-nav-item", 
    "display": "Search", 
    "desc": "Search Database",
    "path": "/search",
    "icon": <SearchSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "auto-messages-nav-item", 
    "display": "Auto Messages", 
    "desc": "Site Database",
    "path": "/auto-messages",
    "icon": <QuickReplySvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "prompts-nav-item", 
    "display": "Prompts", 
    "desc": "Node Database",
    "path": "/prompts",
    "icon": <ForumSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "chat-nav-item", 
    "display": "Chat Tester", 
    "desc": "Node/Main Database",
    "path": "/chat",
    "icon": <EngineerSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "swiperino-chat-models-nav-item", 
    "display": "Swiperino Chat Models", 
    "desc": "Node Database",
    "path": "/swiperino-chat-model",
    "icon": <TerminalSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "admin-users-nav-item", 
    "display": "Users (Admin)", 
    "desc": "Main Database",
    "path": "/users",
    "icon": <PersonAdminSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },


  // {
  //   "name": "customer-support-nav-item", 
  //   "display": "Customer Support", 
  //   "path": "/customer-support",
  //   "icon": <CustomerSupportSvg/>,
  //   "minStatus": AccountStatusEnum.Staff,
  // },
  // {
  //   "name": "feedback-nav-item", 
  //   "display": "Feedback", 
  //   "desc": "Other Messages too",
  //   "path": "/feedback",
  //   "icon": <FeedbackSvg/>,
  //   "minStatus": AccountStatusEnum.Staff,
  // },
  // {
  //   "name": "test", 
  //   "display": "Test",
  //   "path": "/test",
  //   "minStatus": AccountStatusEnum.Staff,
  // },
  // {
  //   "name": "test2", 
  //   "display": "Test2",
  //   "path": "/test2",
  //   "minStatus": AccountStatusEnum.Staff,
  // },
];

export default naviRoutes;