
import {Environment, EnvironmentEnum, SandboxEnvArr} from './env';

const Config = {
  Common:{
    ApiBaseUrl: "http://localhost:3060",
    AppName: "swiperino",
    Company: "dreolo",
    CompanyLink: "https://dreolo.com/",
    SupportEmailName: "mail",
    SupportEmailDomain: "swiperino.com",
  },
  Keys:{
    StripePublicKey: "",
    StripeAnnualPriceId: "",
    StripeMonthlyPriceId: "",
    GoogleAnalytics: "",
    RecaptchaPubKey: "6LdBnLolAAAAABNOe4Nrvakcr0XTNXj3EIL73ykT",
  },
  Sandbox: false,
};


if(SandboxEnvArr.indexOf(Environment) !== -1){
  Config.Sandbox = true;
}

if(Environment === EnvironmentEnum.Release){
  Config.Common.ApiBaseUrl = "https://api.testswiperino.com";
}

if(Environment === EnvironmentEnum.Production || Environment === EnvironmentEnum.ProductionSandbox){
  Config.Keys.RecaptchaPubKey = "6LdBnLolAAAAABNOe4Nrvakcr0XTNXj3EIL73ykT";
}

if(Environment === EnvironmentEnum.Production){
  Config.Common.ApiBaseUrl = "https://api.swiperino.com";
}

export default Config;
