import React, { useState, useEffect } from "react";
import "./swiperino-chat-model.scss";
import usePersistState from "../../../utils/use-persist-state";
import AutosizeTextarea from 'react-textarea-autosize';
import { clone } from "../../../utils/clone";
import * as ObjectUtil from "../../../utils/object";
import Button from "../../elements/button/button";
import {request,unSubRequest} from "../../../utils/request";
import {toast} from 'react-toastify';
import ReactSelect from 'react-select';
import Languages from "../../../data/languages";
import CarouselHeader from '../../common/carousel/carousel-header';
import Carousel from '../../common/carousel/carousel';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import ConfirmModal from "../../common/modal/confirm-modal";


const SwiperinoChatModel = () => {

  return (
    <div className="swiperino-chat-model-comp">
      <h3>Swiperino Chat Model</h3>
      
    </div>
  );
};

export default SwiperinoChatModel;
