import React from 'react';
import './test2.scss';

import mainLogo from'../../../css/imgs/favicon.png';


const Test2 = () => {
  return (
    <div className="test-two">
      <h3>Test2 Component</h3>

      <div className='green-screen'>
        <div className='swiperino-logo'>
          <img src={mainLogo}/>
          <div className='text'>
            Swiperino.com
          </div>
        </div>
      </div>
      

    </div>
  );
};

export default Test2;