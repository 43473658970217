import React from "react";
import Table from '../../../elements/table/table';
import CopyInput from "../../../elements/copy-input/copy-input";

const Likes = ({viewUser, load}) => {

  if(viewUser === null){
    return <div>null</div>
  }

  const userId = viewUser && viewUser.usersId?viewUser.usersId:0;
  const sideItems = [];

  sideItems.push({
    display: ("User Id: "+userId),
    conditions:[{
      action: "=",
      column:"userId", 
      table:"likes",
      value: userId,
    }]
  });

  const columns = [
    {table: "likes",value: "id", index:true, hide:true,},
    {table: "likes",value: "userId", index:true, },
    {table: "likes",value: "likes",},
    {table: "likes",value: "ipAddress", index:true, 
      custom: ({v,cv}) => {
        let val = v[cv];
        return(
          <div>
            <CopyInput value={val}/>
          </div>
        )
      }, 
    },
    {table: "likes",value: "day", index:true, type:"date", defaultOrder:"DESC",},
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"likes",rightTable:"users",leftColumn:"userId",rightColumn:"id",},
  ]
  
  return (
    <div className="user-likes-comp">
      <div className="user-likes-comp-header">
        <h3>User Likes</h3>
      </div>
      <Table 
        displayName="UserLikes"
        name="UserLikesTable"
        database={"Site"}
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          // setShowUser(value);
          // setShowUserModal(true);
        }}
        columns={columns}
        limit={250}
        load={load}
      />
    </div>
  );
};

export default Likes;
