import React,{useState,useEffect} from "react";
import "./user-site-modal.scss";

import { clone } from "../../../utils/clone";
import Modal from "../../common/modal/modal";
import CarouselHeader from "../../common/carousel/carousel-header";
import Carousel from "../../common/carousel/carousel";

import Likes from './user-site-slides/likes';
import Profile from './user-site-slides/profile';

const SlideEnum = {
  Profile: {Key: 1, Path: "profile", Display: "Profile"},
  Likes: {Key: 2, Path: "likes", Display: "Likes"},
}

const UserSiteModal = ({viewUser,show,handleClose,refresh,}) => {

  const [slide,setSlide] = useState(1);
  const [slidToArray,setSlidToArray] = useState([]);

  useEffect(() => {
    let newSlidToArray = slidToArray;
    if(newSlidToArray.indexOf(slide) === -1){
      newSlidToArray = clone(slidToArray);
      newSlidToArray.push(slide);
    }
    setSlidToArray(newSlidToArray);
  },[slide,slidToArray])


  return (
    <Modal 
      className="user-modal" 
      show={show} 
      handleClose={() => {handleClose()}}>
      <div>
        <CarouselHeader className="entry-options hlo" slideTo={slide} forceResize={show}>
          <div onClick={() => { setSlide(SlideEnum.Profile.Key);}}>{SlideEnum.Profile.Display}</div>
          <div onClick={() => { setSlide(SlideEnum.Likes.Key);}}>{SlideEnum.Likes.Display}</div>
        </CarouselHeader>
        <Carousel slideTo={slide}>
          <div><Profile viewUser={viewUser} load={slidToArray.indexOf(SlideEnum.Profile.Key) !== -1} /></div>
          <div><Likes viewUser={viewUser} load={slidToArray.indexOf(SlideEnum.Likes.Key) !== -1} /></div>
        </Carousel>
      </div>
    </Modal>
  );
};

export default UserSiteModal;