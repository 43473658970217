
const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const isArray = function (a) {
  return Array.isArray(a);
};

const toCamelCase = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const toSnakeCase = (s) => {  
  return s.split(/(?=[A-Z])/).join('_').toLowerCase();
};

export const toCamelCaseKeys = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toCamelCase(k)] = toCamelCaseKeys(o[k]);
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return toCamelCaseKeys(i);
    });
  }

  return o;
};

export const toSnakeCaseKeys = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toSnakeCase(k)] = toSnakeCaseKeys(o[k]);
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return toSnakeCaseKeys(i);
    });
  }

  return o;
};

export const prettifyTime = (obj) => {
  if(obj === undefined){return "";}
  let d = new Date(obj);
  const hour = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', second: 'numeric', }).format(d)
  return `${hour}`;
}

export const reverseObject = (object) => {
  var newObject = {};
  var keys = [];

  for (var key in object) {
      keys.push(key);
  }

  for (var i = keys.length - 1; i >= 0; i--) {
    var value = object[keys[i]];
    newObject[keys[i]]= value;
  }       

  return newObject;
}


export const convertBlobToString = (val) => {
  let strData = "";
  try{
    let numArray = Object.values(val);
    let uint8Array = new Uint8Array(numArray);
    let decoder = new TextDecoder("utf-8");
    strData = decoder.decode(uint8Array);
  }catch(e){
    console.log("Could not convert blob to string",e);
  }
  return strData;
}
