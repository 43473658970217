import React,{useState,useEffect} from 'react';
import './subscriptions.scss';
import CarouselHeader from '../../common/carousel/carousel-header';
import Carousel from '../../common/carousel/carousel';
import Table from '../../elements/table/table';

const Subscriptions = () => {

  const [slide,setSlide] = useState(1);

  const sideItems = [];
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"autoMessage",rightTable:"users",leftColumn:"userId",rightColumn:"id",},
  ]

  const columns = [
    {table: "subscriptions",value: "id", index:true,},
    {table: "subscriptions",value: "userId", index:true, ignoreRowClick:true,},
    {table: "subscriptions",value: "stripeUserId",},
    {table: "subscriptions",value: "stripeSubId",},
    {table: "subscriptions",value: "paypalSubId", },
    {table: "subscriptions",value: "dawicoinSubUid", },
    {table: "subscriptions",value: "status", },
    {table: "subscriptions",value: "planName", },
    {table: "subscriptions",value: "planBillingRate", },
    {table: "subscriptions",value: "platform", },
    {table: "subscriptions",value: "tempCreatedDate",},
    {table: "subscriptions",value: "createdDate", type:"date", index:true, defaultOrder:"DESC", ignoreRowClick:true,},
  ];

  const subHistoryColumns = [
    {table: "subscriptionsHistory",value: "id", index:true,},
    {table: "subscriptionsHistory",value: "userId", index:true, },
    {table: "subscriptionsHistory",value: "SubId", index:true,},
    {table: "subscriptionsHistory",value: "planName", },
    {table: "subscriptionsHistory",value: "planBillingRate", },
    {table: "subscriptionsHistory",value: "platform", },
    {table: "subscriptionsHistory",value: "deactivatedDate",},
    {table: "subscriptionsHistory",value: "createdDate", type:"date", },
  ];

  return (
    <div className="subscriptions-comp">
      <h1>Subscriptions</h1>

      <CarouselHeader slideTo={slide}>
        <div onClick={() => {setSlide(1)}}>Subscriptions</div>
        <div onClick={() => {setSlide(2)}}>Subscriptions History</div>
      </CarouselHeader>
      <Carousel slideTo={slide}>
        <div>
        <Table 
            displayName="Subscriptions"
            name="SubscriptionsTable"
            database={"Site"}
            sideItems={sideItems}
            sideItemActiveClickFunc={(item) => {
              // console.log("item",item);
            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {
              // setShowUser(value);
              // setShowUserModal(true);
            }}
            columns={columns}
            returnResultsFunc={(results) => {
              // setResults(results);
            }}
            limit={50}
            />
        </div>
        <div>
        <Table 
            displayName="SubscriptionsHistory"
            name="SubscriptionsHistoryTable"
            database={"Site"}
            sideItems={sideItems}
            sideItemActiveClickFunc={(item) => {
              // console.log("item",item);
            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {
              // setShowUser(value);
              // setShowUserModal(true);
            }}
            columns={subHistoryColumns}
            returnResultsFunc={(results) => {
              // setResults(results);
            }}
            limit={50}
            />
        </div>
      </Carousel>
    </div>
  );

};

export default Subscriptions;