import React, { useState, useEffect } from "react";
import "./chat.scss";
import usePersistState from "../../../utils/use-persist-state";
import AutosizeTextarea from 'react-textarea-autosize';
import { clone } from "../../../utils/clone";
import * as ObjectUtil from "../../../utils/object";
import Button from "../../elements/button/button";
import {request,unSubRequest} from "../../../utils/request";
import {toast} from 'react-toastify';
import ReactSelect from 'react-select';
import Languages from "../../../data/languages";
import CarouselHeader from '../../common/carousel/carousel-header';
import Carousel from '../../common/carousel/carousel';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import ConfirmModal from "../../common/modal/confirm-modal";
import CopyInput from '../../elements/copy-input/copy-input';

let goals = [
  {value: "date", display:"Date",},
  {value: "followers", display:"Followers",},
  {value: "store-front", display:"Store Front",},
  {value: "other", display:"Other",},
];

let statusList = [
  {value: "start", display:"Start",},
  {value: "convo", display:"Convo",},
  {value: "ask-on-date", display:"Ask on date",},
  {value: "asked-on-date", display:"Asked on date",},
  {value: "place", display:"Place",},
  {value: "date-time", display:"Datetime",},
  {value: "time", display:"Time",},
  {value: "done", display:"Done",},
  {value: "notify-user", display:"notify-user",},
]

let defaultPrompts = {system:"",user:"",json:"",}
defaultPrompts.system = `
You are a helpful assistant, specialized in crafting engaging, succinct, and cool responses for dating conversations, as if written by a laid-back person using a dating app like Tinder. 
Please generate responses that are relaxed, playful, and flirtatious, with a tone of casual coolness. Avoid over-explaining or sounding overly enthusiastic. 
Show genuine interest in getting to know the other person, but in a nonchalant way. 
Remember to keep your responses brief and to the point.
`;
defaultPrompts.user = `
Help me think of a response.
Respond in a way that's engaging and relevant to the topic, while staying true to the tone of the conversation.
Avoid expressions that may sound overly formal, dorky, or out of place in the conversation. 
Generate a single response without any additional text or explanation. 
Do not ask them to meet in any way or form. Just talk with them and get to know them.
Carefully detect the language used in the conversation and respond in that language. 
%languagesStr%
Their name is {%usernameStr%} and their userProfile says this: {%userProfileStr%}. 
Here's the context of our conversation: {%convoStr%} 
Here's their latest message: {%lastMessageStr%} 
`
defaultPrompts.json = `
Respond with JSON. Add the response as message.
Add "asked-for-date" to the JSON object.
Carefully read the last message of the conversation. 
If they've asked me on a date, to meet or for a cup of coffee, set it to true. 
`;
defaultPrompts.system = defaultPrompts.system.trim();
defaultPrompts.user = defaultPrompts.user.trim();
defaultPrompts.json = defaultPrompts.json.trim();


const Chat = () => {

  const [slide,setSlide] = useState(1);

  const [leftCurrent,setLeftCurrent] = usePersistState(true,"chatLeftCurrent","boolean"); 
  const [leftList,setLeftList] = useState([]);

  const [loading,setLoading] = useState(false);
  const [id,setId] = usePersistState(0,"chatActiveId","number");
  const [spokenLanguages,setSpokenLanguages] = usePersistState([],"chatSpokeLanguages","array");
  const [customPrompt,setCustomPrompt] = usePersistState("","chatCustomPrompt");
  const [username,setUsername] = usePersistState("","chatUsername");
  const [userProfile,setUserProfile] = usePersistState("","chatUserProfile");

  const [matchId,setMatchId] = usePersistState([],"chatMatchId","string");
  const [goal,setGoal] = usePersistState([],"chatGoal","string");
  const [status,setStatus] = usePersistState([],"chatStatus","string");

  const [convo,setConvo] = usePersistState([],"chatConvo","array");
  const [reply,setReply] = useState("");
  const [replyRaw,setReplyRaw] = useState("");

  const [chatLabel,setChatLabel] = usePersistState("","chatLabel");
  const [showDefaults,setShowDefaults] = usePersistState("","chatShowDefaults","boolean");
  const [enableCustomPrompt,setEnableCustomPrompt] = usePersistState("","chatEnableCustomPrompt","boolean");
  const [systemPrompt,setSystemPrompt] = usePersistState("","chatSystemPrompt");
  const [userPrompt,setUserPrompt] = usePersistState("","chatUserPrompt");
  const [jsonPrompt,setJsonPrompt] = usePersistState("","chatJsonPrompt");

  const [showConfirmReset,setShowConfirmReset] = useState(false);
  const [showConfirmRemove,setShowConfirmRemove] = useState(false);
  const [showConfirmCopy,setShowConfirmCopy] = useState(false);
  const [showConfirmArchive,setShowConfirmArchive] = useState(false);

  useEffect(() => {
    fetchList(leftCurrent);
  },[leftCurrent])

  useEffect(() => {
    return () => {
      unSubRequest("admin-chat");
    }
  },[]);

  const send = (customPrompt,spokenLanguages,username,userProfile,convo) => {

    let spokenLanguagesStr = spokenLanguagesToString(spokenLanguages);

    let data = {  
      matchId: matchId,
      customPrompt,
      spokenLanguages: spokenLanguagesStr,
      username,
      userProfile,
      convo,
      matchUserId: id?id:0,
    }
    if(enableCustomPrompt){
      data.options = {
        useCustomPrompts: true,
        customSystemPrompt: systemPrompt,
        customUserPrompt: userPrompt,
        customJsonPrompt: jsonPrompt,
      }
    }

    setLoading(true)    
    request("chat-admin-recommend","/chat/admin-recommend","POST", data, {
      then: function(res){ 
        console.log("res",res.data.res);
        let message = "";
        let messageRaw = "";
        let status = "";
        try{
          message = res.data.res.message;
          messageRaw = res.data.res.messageRaw;
          status = res.data.res.status;
          if(res.data.res.newStatus){
            status = res.data.res.newStatus;
          }

        }catch(e){
          console.log("e",e);
        }
        setReply(message);
        setReplyRaw(messageRaw);
        setStatus(status);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const resetChat = () => { 
    setId(0);
    setMatchId(0);
    setSpokenLanguages("");
    setCustomPrompt("");
    setUsername("");
    setUserProfile("");
    setConvo([]);
    setReply("");
    setChatLabel("");
    setEnableCustomPrompt(false);
    setSystemPrompt("");
    setUserPrompt("");
    setJsonPrompt("");
  }

  const fetchList = (archive) => {
    let endpoint = "/fetch-test-chat-prompts-list";
    if(archive){
      endpoint += "?archive=on";
    }
    setLoading(true)    
    request("fetch-test-chat-prompts-list",endpoint,"GET", {}, {
      then: function(res){ 
        setLeftList(res.data.res);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const fetchById = (id) => {
    setLoading(true)    
    request("fetch-test-chat-prompts","/fetch-test-chat-prompts/"+id,"GET", {}, {
      then: function(res){ 
        let results = res.data.res;
        console.log("results",results);

        setMatchId(results.matchId);
        setGoal(results.goal);
        setStatus(results.status);

        setId(results.id);
        setCustomPrompt(results.customPrompt);
        setChatLabel(results.label);
        setSlide(results.customSystem === 1?2:1);
        setSystemPrompt(results.customSystemPrompt);
        setUserPrompt(results.customUserPrompt);
        setJsonPrompt(results.customJsonPrompt);
        setUsername(results.username);
        setSpokenLanguages(spokenLanguagesToObjectArray(results.spokenLanguages))

        let convo = results.convo;
        let userProfile = results.userProfile;

        convo = ObjectUtil.convertBlobToString(convo);
        try{
          convo = JSON.parse(convo);
        }catch(e){
          console.log("failed try parse");
        }
        userProfile = ObjectUtil.convertBlobToString(userProfile);

        setUserProfile(userProfile);
        setConvo(convo);

      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }
  const save = (id,leftCurrent) => {

    let spokenLanguagesStr = spokenLanguagesToString(spokenLanguages);

    let data = {
      matchId: matchId,
      label: chatLabel, 
      customSystem: slide === 2?1:0, 
      spokenLanguages: spokenLanguagesStr, 
      customPrompt: customPrompt, 
      username: username, 
      userProfile: userProfile, 
      convo: convo,
    }
    if(enableCustomPrompt){
      data.options = {
        useCustomPrompts: true,
        customSystemPrompt: systemPrompt,
        customUserPrompt: userPrompt,
        customJsonPrompt: jsonPrompt,
      }
    }

    setLoading(true)    
    request("save-test-chat-prompts","/save-test-chat-prompts/"+id,"POST", data, {
      then: function(res){ 
        let newId = id;
        if(res.data.res.id){
          newId = parseInt(res.data.res.id);
          fetchList(leftCurrent);
        }
        setId(newId);
        toast.success("Saved");
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }
  const toggleArchive = (id) => {
    setLoading(true)    
    request("toggle-archive-test-chat-prompts","/toggle-archive-test-chat-prompts/"+id,"POST", {}, {
      then: function(res){ toast.success("Archive Toggled"); fetchList(!leftCurrent);},
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }
  const remove = (id) => {
    setLoading(true)    
    request("remove-test-chat-prompts","/remove-test-chat-prompts/"+id,"POST", {}, {
      then: function(res){ resetChat(); toast.success("Deleted");},
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const spokenLanguagesToString = (spokenLanguages) => {
    let spokenLanguagesStr = "";
    for (let i = 0; i < spokenLanguages.length; i++) {
      const spokenLanguage = spokenLanguages[i];
      spokenLanguagesStr += (i === 0)?"":",";
      spokenLanguagesStr += spokenLanguage.value;
    }
    return spokenLanguagesStr;
  }
  const spokenLanguagesToObjectArray = (spokenLanguagesString) => {
    let array = spokenLanguagesString.split(",");
    let spokenLanguagesArray = [];
    for (let i = 0; i < array.length; i++) {
      const spokenLanguage = array[i];
      for (let j = 0; j < Languages.length; j++) {
        const language = Languages[j];
        if(spokenLanguage === language.value){
          spokenLanguagesArray.push(language);
          break;
        }
      }
    }
    return spokenLanguagesArray;
  }

  const updateMatch = (matchId, goal, status) => {
    let body = {matchId,goal,status}
    setLoading(true)    
    request("update-match","/admin-update-match/","POST", body, {
      then: function(res){},
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  return (
    <div className="chat-comp">

      <div className="chat-header">
        <h3>Chat Tester</h3>
        <div className="buttons">
          <Button parentClassName={"delete-chat-button"} status={loading?"loading":"delete"} onClick={() => {
            setShowConfirmRemove(true);
          }}>Delete</Button>
          <Button parentClassName={"archive-chat-button"} status={loading?"loading":"archive"} onClick={() => {
            setShowConfirmArchive(true);
          }}>Archive Toggle</Button>
          <Button parentClassName={"new-chat-button"} status={loading?"loading":"copy"} onClick={() => {
            setShowConfirmCopy(true);
          }}>Copy</Button>
          <Button parentClassName={"new-chat-button"} status={loading?"loading":"add"} onClick={() => {
            setShowConfirmReset(true);
          }}>New</Button>
          <Button parentClassName={"save-chat-button"} status={loading?"loading":"save"} onClick={() => {
            save(id,leftCurrent);
          }}>Save</Button>
        </div>
      </div>
      
      {/* textOn,textOff,width,height,divisor,borderRadius,loading,disable */}
      <div className="chat-container">
        <div className="left">
          <SwitchToggle 
            textOn={"Current"}
            textOff={"Archived"}
            width={200}
            height={40}
            isOn={leftCurrent}
            divisor={4}
            borderRadius={2}
            onClickFunc={() => {
              let newLeftCurrent = !leftCurrent;
              setLeftCurrent(newLeftCurrent);
            }}
            />
          <div className="left-items">
            {leftList.map((v,i) => {
              let activeClass = id === v.id?"active":"";
              return(
                <div className={"item "+activeClass} key={i} onClick={() => {fetchById(v.id)}}>
                  <div>{v.label}</div>
                  <span>{v.id} - {v.archived?"Archived":"Current"}</span>
                </div>
              )
            })}
          </div>
        </div>
        <div className="center">
          <div>
            <label>Chat Label</label>
            <input type="text" value={chatLabel} onChange={(e) => {setChatLabel(e.target.value)}}/>
          </div>
          <CarouselHeader slideTo={slide}>
            <div onClick={() => {setSlide(1)}}>Default Settings</div>
            <div onClick={() => {setSlide(2)}}>Custom Rpompt System</div>
          </CarouselHeader>
          <Carousel slideTo={slide}>
            <div>
              <div>
                <label>Spoken languages</label>
                <ReactSelect placeholder="Select Languages"
                  className="custom-react-select" 
                  classNamePrefix="custom-react-select-inner"
                  isMulti
                  onChange={(e) => {setSpokenLanguages(e);}}
                  value={spokenLanguages}
                  options={Languages}
                  />
              </div>
              <div>
                <label>Custom Prompt</label>
                <AutosizeTextarea onChange={(e) => {setCustomPrompt(e.target.value)}} value={customPrompt}></AutosizeTextarea>
              </div>
              <div>
                <label>Username</label>
                <input type="text" value={username} onChange={(e) => {setUsername(e.target.value)}}/>
              </div>
              <div>
                <label>User Profile</label>
                <AutosizeTextarea onChange={(e) => {setUserProfile(e.target.value)}} value={userProfile}></AutosizeTextarea>
              </div>
              <div className="flex">
                <div>
                  <label>MatchId</label>
                  <input type="text" value={matchId} disabled/>
                </div>
                <div>
                  <label>Goal</label>
                  <select value={goal} onChange={(e) => {
                    let val = e.target.value;
                    setGoal(val);
                    updateMatch(matchId, val, status);
                  }} disabled={id === 0 || loading}>
                    {goals.map((v,i) => {
                      let {value,display} = v;
                      return <option key={i} value={value}>{display}</option>
                    })}
                  </select>
                </div>
                <div>
                  <label>Status</label>
                  <select value={status} onChange={(e) => {
                    let val = e.target.value;
                    setStatus(val);
                    updateMatch(matchId, goal, val);
                  }} disabled={id === 0 || loading}>
                    {statusList.map((v,i) => {
                      let {value,display} = v;
                      return <option key={i} value={value}>{display}</option>
                    })}
                  </select>
                </div>
              </div>              
            </div>
            <div>
              <div className="toggles">
                <SwitchToggle 
                  isOn={enableCustomPrompt} 
                  onClickFunc={() => {
                    setEnableCustomPrompt(!enableCustomPrompt);
                  }}  
                  textOn="Enabled"
                  textOff="Disabled"
                  width={240}
                  height={40}
                  divisor={4}
                  borderRadius={2}

                  />
                <SwitchToggle 
                  isOn={showDefaults} 
                  onClickFunc={() => {
                    setShowDefaults(!showDefaults);
                  }}  
                  textOn="Show Defaults"
                  textOff="Hide Defaults"
                  width={240}
                  height={40}
                  divisor={4}
                  borderRadius={2}

                  />
              </div>
              <div className="prompt-div">
                <label>System Prompt</label>
                <AutosizeTextarea onChange={(e) => {setSystemPrompt(e.target.value)}} value={systemPrompt}></AutosizeTextarea>
                <div className={"defaults"+((showDefaults)?"":" hide")}>
                  <label>Default System Prompt</label>
                  <CopyInput value={defaultPrompts.system} textarea={true} />
                </div>
              </div>
              <div className="prompt-div">
                <label>User Prompt</label>
                <AutosizeTextarea onChange={(e) => {setUserPrompt(e.target.value)}} value={userPrompt}></AutosizeTextarea>
                <div className={"defaults"+((showDefaults)?"":" hide")}>
                  <label>Default User Prompt</label>
                  <CopyInput value={defaultPrompts.user} textarea={true} />
                </div>
              </div>
              <div className="prompt-div">
                <label>JSON Prompt</label>
                <AutosizeTextarea onChange={(e) => {setJsonPrompt(e.target.value)}} value={jsonPrompt}></AutosizeTextarea>
                <div className={"defaults"+((showDefaults)?"":" hide")}>
                  <label>Default JSON Prompt</label>
                  <CopyInput value={defaultPrompts.json} textarea={true} />
                </div>
              </div>
            </div>
          </Carousel>
          

          <div className="convo">
            <label>Convo</label>
            
            <div className="convo-list">
              {convo.map((v,i) => {
                let flags = v.flags?JSON.stringify(v.flags):""
                return (
                  <div className={"convo-message "+v.side} key={i}>
                    <AutosizeTextarea onChange={(e) => {
                      let newConvo = clone(convo);
                      newConvo[i].text = e.target.value;
                      setConvo(newConvo);
                    }} value={v.text}></AutosizeTextarea>
                    {v.flags?<input type="text" value={flags} disabled/>:null}
                    <div className="buttons">
                      <div>
                        <Button status={v.side==="Me"?"back":"forward"} onClick={() => {
                          let newConvo = clone(convo);
                          newConvo[i].side = v.side === "Me"?"Them":"Me"
                          newConvo[i].user = v.side === "Me"?"them":"self"
                          setConvo(newConvo);
                        }}></Button>
                      </div>
                      <div className="up-down">
                        <Button disabled={i===0?true:false} status={"up"} onClick={() => {
                          let newConvo = clone(convo);
                          newConvo.splice(i, 1);
                          newConvo.splice(i-1, 0, v);
                          setConvo(newConvo);
                        }}></Button>
                        <Button disabled={i===convo.length-1?true:false} status="down" onClick={() => {
                          let newConvo = clone(convo);
                          newConvo.splice(i, 1);
                          newConvo.splice(i+1, 0, v);
                          setConvo(newConvo);
                        }}></Button>
                      </div>
                      <div>
                        <Button status="delete" onClick={() => {
                          let newConvo = clone(convo);
                          newConvo.splice(i, 1);
                          setConvo(newConvo);
                        }}></Button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <Button parentClassName={"add-button"} status="add" onClick={() => {
              let newConvo = clone(convo);
              newConvo.push({side:"Me",text:"",user:"self"})
              setConvo(newConvo);
            }}>
              New Message
            </Button>
            <div>
              <label>Response Raw</label>
              <AutosizeTextarea value={replyRaw} onChange={(e) => {setReplyRaw(e.target.value)}}></AutosizeTextarea>
            </div>
            <div>
              <label>Response</label>
              <AutosizeTextarea value={reply} onChange={(e) => {setReply(e.target.value)}}></AutosizeTextarea>
            </div>
            
          </div>
          <div className="bottom-buttons">
            <div>
              <Button status={loading?"loading":"add"} onClick={() => {
                let text = reply;
                let flags = null;
                try{
                  let jsonObj = JSON.parse(text);
                  text = jsonObj.message
                  delete jsonObj.message;
                  if (Object.keys(jsonObj).length >= 1) {
                    flags = jsonObj;
                  }
                }catch(e){}
                let obj = {
                  side:"Me",
                  text:text,
                  user:"self"
                }
                if(flags){
                  obj.flags = flags;
                }
                let cloneConvo = clone(convo);
                cloneConvo.push(obj);
                setConvo(cloneConvo);
                setReply("");
                setReplyRaw("");
              }}>
                Add Response
              </Button>
            </div>
            <div>
              <Button status={loading?"loading":"send"} onClick={() => {
                send(customPrompt,spokenLanguages,username,userProfile,convo)
              }}>
                Send
              </Button>
            </div>
          </div>
            
        </div>
      </div>

      <ConfirmModal
        show={showConfirmReset || showConfirmRemove || showConfirmCopy || showConfirmArchive}
        handleClose={(confirmed) => {
          if(confirmed){
            if(showConfirmReset){ resetChat();}
            if(showConfirmRemove){ remove(id);}
            if(showConfirmCopy){ setId(0);}
            if(showConfirmArchive){ toggleArchive(id);}
          }
          setShowConfirmReset(false);
          setShowConfirmRemove(false);
          setShowConfirmCopy(false);
          setShowConfirmArchive(false);
        }}
        >
          {(showConfirmRemove)?<div>Are you sure you want to Delete this chat and reset it?</div>:null}
          {(showConfirmReset)?<div>Are you sure you want to start a new chat? This will clear all locally unsaved data.</div>:null}
          {(showConfirmCopy)?<div>Are you sure you want to copy this chat? This will just set this current chat to save as a new one instead of updating the old one. </div>:null}
          {(showConfirmArchive)?<div>Are you sure you want to archive this chat? </div>:null}
      </ConfirmModal>
      
    </div>
  );
};

export default Chat;
