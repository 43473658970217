import React from "react";
import "./auto-messages.scss";
import Table from '../../elements/table/table';
import CopyInput from "../../elements/copy-input/copy-input";
import TextareaAutosize from 'react-textarea-autosize'

const AutoMessages = () => {

  const sideItems = [];

  const columns = [
    {table: "autoMessage",value: "id", index:true, },
    {table: "autoMessage",value: "userId", index:true, },
    {table: "users",value: "email", 
      custom: ({v,cv}) => {
        let val = v[cv];
        return(
          <div>
            <CopyInput value={val}/>
          </div>
        )
      },
    },
    {table: "autoMessage",value: "message",
      custom: ({v,cv}) => {
        let val = v[cv];
        return(
          <div>
            <TextareaAutosize value={val} readOnly></TextareaAutosize>
          </div>
        )
      },
    },
  ]
  const joinOptions = [
    {joinType:"LEFT JOIN",leftTable:"autoMessage",rightTable:"users",leftColumn:"userId",rightColumn:"id",},
  ]

//   -- -----------------------------------------------------
// -- Table `likes`
// -- -----------------------------------------------------
// CREATE TABLE IF NOT EXISTS `likes` (
//   `id` int(11) NOT NULL AUTO_INCREMENT PRIMARY KEY,
//   `user_id` int(11) NOT NULL,
//   `likes` int(11) NOT NULL,
//   `ip_address` varchar(255) DEFAULT NULL,
//   `day` DATETIME NOT NULL
// ) ENGINE=InnoDB DEFAULT CHARSET=utf8;

// CREATE INDEX `likes_one_index` ON `likes` (`user_id`,`day`);

  return (
    <div className="am-comp">
      <div className="am-comp-header">
        <h3>Auto Messages</h3>
      </div>
      <Table 
        displayName="AutoMessages"
        name="AutoMessagesTable"
        database={"Site"}
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          // setShowUser(value);
          // setShowUserModal(true);
        }}
        columns={columns}
        limit={250}
      />
    </div>
  );
};

export default AutoMessages;
