export const faq = [
  {
    question: {
      en: "How long does my subscription last?",
      ja: "How long does my subscription last? ja",
    },
    answer: {
      en: "You can subscribe for 1 month, 3 months or 12 months",
      ja: "You can subscribe for 1 month, 3 months or 12 months ja",
    },
  },{
    question: {
      en: "Do you have a free trial?",
      ja: "Do you have a free trial? ja",
    },
    answer: {
      en: "Yes, we offer 1 week free trial to all users",
      ja: "Yes, we offer 1 week free trial to all users ja",
    },
  },{
    question: {
      en: "How do I cancel subscription?",
      ja: "How do I cancel subscription? ja",
    },
    answer: {
      en: "You can cancel your subscription by heading over to your 'My Account' page",
      ja: "You can cancel your subscription by heading over to your 'My Account' page ja",
    },
  },{
    question: {
      en: "What do I need to start learning?",
      ja: "What do I need to start learning? ja",
    },
    answer: {
      en: "You just need a computer with an internet connection.",
      ja: "You just need a computer with an internet connection. ja",
    },
  },{
    question: {
      en: "How much time should I expect to commit to learning?",
      ja: "How much time should I expect to commit to learning? ja",
    },
    answer: {
      en: "You can study as little or as often as you want. Learn at your own pace",
      ja: "You can study as little or as often as you want. Learn at your own pace ja",
    },
  },{
    question: {
      en: "How old should I be to start coding?",
      ja: "How old should I be to start coding? ja",
    },
    answer: {
      en: "If you're old enough to be able to use a computer then you're more than able to start learning how to code",
      ja: "If you're old enough to be able to use a computer then you're more than able to start learning how to code ja",
    },
  },
]






