import React from "react";
import "./likes.scss";
import Table from '../../elements/table/table';
import CopyInput from "../../elements/copy-input/copy-input";

const Likes = () => {

  const sideItems = [];

  const columns = [
    {table: "likes",value: "id", index:true, hide:true,},
    {table: "likes",value: "userId", index:true, },
    {table: "users",value: "email", 
      custom: ({v,cv}) => {
        let val = v[cv];
        return(
          <div>
            <CopyInput value={val}/>
          </div>
        )
      },
    },
    {table: "likes",value: "likes",},
    {table: "likes",value: "ipAddress", index:true, 
      custom: ({v,cv}) => {
        let val = v[cv];
        return(
          <div>
            <CopyInput value={val}/>
          </div>
        )
      }, 
    },
    {table: "likes",value: "day", index:true, type:"date", defaultOrder:"DESC",},
  ]
  const joinOptions = [
    {joinType:"LEFT JOIN",leftTable:"likes",rightTable:"users",leftColumn:"userId",rightColumn:"id",},
  ]
  
  return (
    <div className="likes-comp">
      <div className="likes-comp-header">
        <h3>Likes</h3>
      </div>
      <Table 
        displayName="Likes"
        name="LikesTable"
        database={"Site"}
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          // setShowUser(value);
          // setShowUserModal(true);
        }}
        columns={columns}
        limit={250}
      />
    </div>
  );
};

export default Likes;
