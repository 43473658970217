import React, { useState } from "react";
import "./users-site.scss";
import Table from '../../elements/table/table';
import CopyInput from "../../elements/copy-input/copy-input";
import UserSiteModal from './user-site-modal';
import UserSiteStatusEnum,{UserSiteStatusIdEnum} from "../../../enums/user-site-status";


const UsersSite = () => {

  const [show,setShow] = useState(false);
  const [val,setVal] = useState(null);

  const sideItems = [{display:"Any",conditions:[]}];
  for (let key in UserSiteStatusEnum) {
    let accountStatus = UserSiteStatusEnum[key];
    sideItems.push({
      display: key,
      conditions:[{
        action: "=",
        column:"status", 
        table:"users",
        value: accountStatus,
      }]
    });
  }

  const columns = [
    {table: "users",value: "id", index:true, },
    {table: "users",value: "email", index:true, 
    custom: ({v,cv}) => {
      let email = v[cv];
      return(
        <div>
          <CopyInput value={email}/>
        </div>
      )
    }, 
    },
    {table: "users",value: "status", display:"Status",index:true, 
      custom: ({v,cv}) => {
        let status = parseInt(v[cv]);
        let statusText = (status in UserSiteStatusIdEnum)?UserSiteStatusIdEnum[status]:status;
        return(
          <div>{statusText}</div>
        )
      },  
    },
    {table: "users",value: "ip", index:true, 
      custom: ({v,cv}) => {
        let ip = v[cv];
        return(
          <div>
            <CopyInput value={ip}/>
          </div>
        )
      }, 
    },

    {table: "users",value: "createDate", index:true, type:"date", defaultOrder:"DESC",},
    // {table: "likes",value: "day", index:true, type:"date",},
    // {table: "likes",value: "likes", index:true, },
  ]
  const joinOptions = [
    // {joinType:"INNER JOIN",leftTable:"users",rightTable:"likes",leftColumn:"id",rightColumn:"userId",},
  ]

  return (
    <div className="users-comp">
      <div className="users-comp-header">
        <h3>Users</h3>
      </div>
      <Table 
        displayName="Users"
        name="UsersTable"
        database={"Site"}
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setVal(value);
          setShow(true);
        }}
        buttons={[
          { 
            status: "refresh",
            text:"IPs",
            onClick: () => {
              alert("i need code");
            }
          },
          { 
            status: "refresh",
            text:"Refs",
            onClick: () => {
              alert("i need code");
            }
          },
        ]}
        columns={columns}
        limit={250}
      />

      <UserSiteModal
        show={show} 
        handleClose={() => {
          setShow(false)
          setVal(null)
        }}
        viewUser={val}
        />
    </div>
  );
};

export default UsersSite;
