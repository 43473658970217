import React, { useState } from "react";
import "./prompts.scss";
import Table from '../../elements/table/table';
import TextareaAutosize from 'react-textarea-autosize'
import Modal from "../../common/modal/modal";
import { clone } from "../../../utils/clone";
import Button from "../../elements/button/button";
import { toast } from "react-toastify";
import { request } from "../../../utils/request";
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';

const convertBlobToString = (val) => {
  let numArray = Object.values(val);
  let uint8Array = new Uint8Array(numArray);
  let decoder = new TextDecoder("utf-8");
  let strData = decoder.decode(uint8Array);
  return strData;
}

const Prompts = () => {

  const [loading,setLoading] = useState(false);
  const [show,setShow] = useState(false);
  const [val,setVal] = useState(null);

  const sideItems = [{display:"Any",conditions:[]}];
  sideItems.push({display: "Unseen",conditions:[{action: "=",column:"seen", table:"prompts",value: 0,}]});
  sideItems.push({display: "Unseen Bad",conditions:[{action: "=",column:"seen", table:"prompts",value: 0,},{action: "=",column:"feedback", table:"prompts",value: 2,}]});
  sideItems.push({display: "Unseen Good",conditions:[{action: "=",column:"seen", table:"prompts",value: 0,},{action: "=",column:"feedback", table:"prompts",value: 1,}]});
  sideItems.push({display: "Seen Bad",conditions:[{action: "=",column:"seen", table:"prompts",value: 1,},{action: "=",column:"feedback", table:"prompts",value: 2,}]});
  sideItems.push({display: "Seen Good",conditions:[{action: "=",column:"seen", table:"prompts",value: 1,},{action: "=",column:"feedback", table:"prompts",value: 1,}]});
  sideItems.push({display: "Problem",conditions:[{action: "=",column:"problem", table:"prompts",value: 1,}]});
  sideItems.push({display: "Seen",conditions:[{action: "=",column:"seen", table:"prompts",value: 1,}]});
  sideItems.push({display: "No Problem",conditions:[{action: "=",column:"problem", table:"prompts",value: 0,}]});
  sideItems.push({display: "Bad",conditions:[{action: "=",column:"feedback", table:"prompts",value: 2,}]});
  sideItems.push({display: "Good",conditions:[{action: "=",column:"feedback", table:"prompts",value: 1,}]});



  const columns = [
    {table: "prompts",value: "id", index:true, hide:true, },
    {table: "prompts",value: "userId", index:true, },
    {table: "prompts",value: "dataPass", hide:true, },
    {table: "prompts",value: "chatgptTokensUsed", hide:true,},
    {table: "prompts",value: "swiperinoTokensUsed", hide:true},
    {table: "prompts",value: "estimatedCost", },
    {table: "prompts",value: "estimatedRevenue", hide:true},
    {table: "prompts",value: "model",hide:true },
    {table: "prompts",value: "swiperinoModel", },
    {table: "prompts",value: "response", ignoreRowClick:true,
      custom: ({v,cv}) => {
        let strData = convertBlobToString(v[cv]);
        return(<div><TextareaAutosize value={strData} /></div>)
      }, 
    },
    {table: "prompts",value: "responseRaw", ignoreRowClick:true, hide:true,
      custom: ({v,cv}) => {
        let strData = convertBlobToString(v[cv]);
        return(<div><TextareaAutosize value={strData} /></div>)
      }, 
    },
    {table: "prompts",value: "seen", index:true, hide:true,
      custom: ({v,cv}) => {
        return(<div>{v[cv]?"Yes":"No"}</div>)
      }, 
    },
    {table: "prompts",value: "problem", index:true, hide:true,
      custom: ({v,cv}) => {
        return(<div>{v[cv]?"Yes":"No"}</div>)
      }, 
    },
    {table: "prompts",value: "problemDetails", ignoreRowClick:true,
      custom: ({v,cv}) => {
        let val = v[cv];
        if(!val){return "---";}
        return(<div><TextareaAutosize value={val} /></div>)
      }, 
    },
    {table: "prompts",value: "feedbackComment", ignoreRowClick:true,
      custom: ({v,cv}) => {
        let val = v[cv];
        if(!val){return "---";}
        return(<div><TextareaAutosize value={val} /></div>)
      }, 
    },
    {table: "prompts",value: "createdDate", index:true, type:"date", defaultOrder:"DESC",},
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"likes",rightTable:"users",leftColumn:"userId",rightColumn:"id",},
  ]

  const toggleSeen = (id) => {
    setLoading(true);
    request("prompt-toggle-seen","/prompt-toggle-seen","POST", {id:id}, {
      then: function(res){
        toast.success("Toggled Seen");
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const toggleProblem = (id) => {
    setLoading(true);
    request("prompt-toggle-problem","/prompt-toggle-problem","POST", {id:id}, {
      then: function(res){
        toast.success("Toggled Problem");
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const saveProblemDetails = (id,problemDetails) => {
    setLoading(true);
    request("prompt-save-problem-details","/prompt-save-problem-details","POST", {id:id,problemDetails:problemDetails}, {
      then: function(res){
        toast.success("Details Updated");
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }
  
  return (
    <div className="prompts-comp">
      <div className="prompts-comp-header">
        <h3>Prompts</h3>
      </div>
      <Table 
        displayName="Prompts"
        name="PromptsTable"
        database={"Node"}
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          let newVal = clone(value);
          try{
            let strData = convertBlobToString(value.promptsDataPass);
            let objData = JSON.parse(strData);
            newVal.promptsDataPass = objData;

            let strData2 = convertBlobToString(value.promptsResponse);
            newVal.promptsResponse = strData2;

            let strData3 = convertBlobToString(value.promptsResponseRaw);
            newVal.promptsResponseRaw = strData3;
          }catch(e){
            console.log("Failed JSON parse",e);
          }
          setVal(newVal);
          setShow(true);
        }}
        columns={columns}
        limit={10}
      />

      <Modal show={show} handleClose={() => {setShow(false);}}>
        <div>
          <h3>Prompt Details</h3>
          <h4>Model: {val !== null && val.promptsDataPass.model?val.promptsDataPass.model:null}</h4>
          <h4>Temperature: {val !== null && val.promptsDataPass.temperature?val.promptsDataPass.temperature:null}</h4>
          <div>
            <h4>Messages: </h4>
            <div className="messages-list">
            {(val !== null && val.promptsDataPass.messages?
                val.promptsDataPass.messages.map((v,i) => {
                  return (
                    <div key={i} className="message">
                      <div>Role: {v.role}</div>
                      <TextareaAutosize value={v.content} readOnly></TextareaAutosize>
                    </div>
                  )
                })
            :null)}  
            <div className="message">
              <div>Response Raw</div>
              <TextareaAutosize value={val !== null && val.promptsResponseRaw?val.promptsResponseRaw:""} readOnly></TextareaAutosize>
            </div>
            <div className="message">
              <div>Response</div>
              <TextareaAutosize value={val !== null && val.promptsResponse?val.promptsResponse:""} readOnly></TextareaAutosize>
            </div>
            </div>
          </div>

          <div className="buttons">
            <div>
              <label>Toggle Seen</label>
              <SwitchToggle 
                isOn={val?val.promptsSeen:0}
                onClickFunc={() => {
                  toggleSeen(val.promptsId);
                  let cloneVal = clone(val);
                  cloneVal.promptsSeen = (val.promptsSeen === 0)?1:0;
                  setVal(cloneVal);
                }}
                loading={loading}
                />
            </div>
            
            <div>
              <label>Toggle Problem</label>
              <SwitchToggle 
                isOn={val?val.promptsProblem:0}
                onClickFunc={() => {
                  toggleProblem(val.promptsId);
                  let cloneVal = clone(val);
                  cloneVal.promptsProblem = (val.promptsProblem === 0)?1:0;
                  setVal(cloneVal);
                }}
                loading={loading}
                />
              
            </div>
            <div>
              <label>Problem Details</label>
              <div className="textarea-div">
                <TextareaAutosize 
                  disabled={loading}
                  placeholder="Problem Details" 
                  value={val?val.promptsProblemDetails:""} 
                  onChange={(e) => {
                    let cloneVal = clone(val);
                    cloneVal.promptsProblemDetails = e.target.value;
                    setVal(cloneVal);
                  }}
                >
                </TextareaAutosize>
                <Button status={loading?"loading":"save"} type="button" onClick={() => {
                  saveProblemDetails(val.promptsId,val.promptsProblemDetails);
                }}>Save</Button>
              </div>
              
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Prompts;
