const UserSiteStatusEnum = {
  Banned:0,
  EmailUnverified:1,
  Starter:2,
  Basic:3,
  Standard:4,
  Premium:5,
  Pro:6,
  Elite:7,
  Admin:8,
}

export const UserSiteStatusIdEnum = {
  0:"Banned",
  1:"EmailUnverified",
  2:"Starter",
  3:"Basic",
  4:"Standard",
  5:"Premium",
  6:"Pro",
  7:"Elite",
  8:"Admin",
}

export default UserSiteStatusEnum;