import React,{useState,useEffect} from 'react';
import './search.scss';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';
import Table from '../../elements/table/table';
import Textarea from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import {toast} from 'react-toastify';
import {request} from '../../../utils/request';
import {KlineIntervalEnum} from '../../../enums/kline-interval';
import CopyInput from '../../elements/copy-input/copy-input';
import Modal from '../../common/modal/modal';

const Search = () => {

  const [loading,setLoading] = useState(false);
  const [show,setShow] = useState(false);
  const [articleKeywords,setArticleKeywords] = useState("");
  const [loadingRefreshId,setLoadingRefreshId] = useState(0);
  const [results,setResults] = useState(null);
  const [keywords,setKeywords] = useState("");
  const [slide,setSlide] = useState(1);
  const [refreshInc,setRefreshInc] = useState(1);
  const [autoUpdateWords,setAutoUpdateWords] = useState(false);

  const [editId,setEditId] = useState(0);
  const [vol,setVol] = useState("");
  const [lang,setLang] = useState("");

  useEffect(() => {
    if(autoUpdateWords && loadingRefreshId === 0){
      let found = false;
      let id = 0;
      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        let newId = result.keywordsId;
        let updatedDate = result.keywordsUpdatedDate;
        if(updatedDate === null){ 
          id = newId;
          found = true;
          break; 
        }
        let time = new Date().getTime() - KlineIntervalEnum.MonthOne;
        updatedDate = new Date(updatedDate).getTime();
        if(time > updatedDate){
          id = newId;          
          found = true;
          break;
        }
      }
      refreshKeyword(id,true);
      setAutoUpdateWords(found);
    }
  },[results,loadingRefreshId,autoUpdateWords])


  const sideItems = [];
  const columns = [
    {table: "keywords",value: "Del", type:"date", ignore:true, ignoreRowClick:true,
      custom: ({v,cv}) => {
        let id = v["keywordsId"];
        return(
          <div className="buttons left">
            <Button status="delete" onClick={() => {
              deleteKeyword(id);
            }}></Button>
          </div>
        )
      },  
    },
    {table: "keywords",value: "id", index:true, hide:true},
    {table: "keywords",value: "keyword", index:true, ignoreRowClick:true,
      custom: ({v,cv}) => <CopyInput display={""} value={(v[cv])}/>
    },
    {table: "keywords",value: "volume",index:true, ignoreRowClick:true,
      custom: ({v,cv}) => { 
        let id = v["keywordsId"];
        let value = editId === id?vol:v[cv]
        return(
          <form className='volume' onSubmit={(e) => {e.preventDefault();editKeyword(id,vol,lang)}}>
            <input 
              onDoubleClick={() => {
                setVol(v["keywordsVolume"]);
                setLang(v["keywordsLanguage"]);
                setEditId(id)
              }}
              readOnly={editId !== id}
              type="text" value={value}  onChange={(e) => {setVol(e.target.value)}}/>
          </form>
        )
      },  
    },
    {table: "keywords",value: "language",index:true, ignoreRowClick:true,
      custom: ({v,cv}) => {
        let id = v["keywordsId"];
        let value = editId === id?lang:v[cv]
        return(
          <form className='language' onSubmit={(e) => {e.preventDefault();editKeyword(id,vol,lang)}}>
            <input 
              onDoubleClick={() => {
                setVol(v["keywordsVolume"]);
                setLang(v["keywordsLanguage"]);
                setEditId(id)
              }}
              readOnly={editId !== id}
              type="text" value={value} onChange={(e) => {setLang(e.target.value)}} />
          </form>
        )
      },  
    },
    {table: "keywords",value: "updatedDate", type:"date", index:true,  ignoreRowClick:true,},
    {table: "keywords",value: "createdDate", type:"date", index:true, defaultOrder:"DESC", ignoreRowClick:true,},
    {table: "keywords",value: "action", type:"date", ignore:true, ignoreRowClick:true,
      custom: ({v,cv}) => {
        let id = v["keywordsId"];
        let keyword = v['keywordsKeyword'];
        return(
          <div className="buttons right">
            {editId !== id?<>
              <Button status="edit" onClick={() => {
                setVol(v["keywordsVolume"]);
                setLang(v["keywordsLanguage"]);
                setEditId(id);
              }}></Button>
            </>:null}

            {editId === id?<>
              <Button status="close" onClick={() => {
                setEditId(0);
              }}></Button>
              <Button status="save" onClick={() => {
                editKeyword(editId,vol,lang);
              }}></Button>
            </>:null}

            <Button status={loadingRefreshId === id?"loading":"refresh"} onClick={() => {
              refreshKeyword(id);
            }}></Button>

            <Button parentClassName={"new-article"} status="add" onClick={() => {
              setArticleKeywords(keyword);
              setShow(true);
            }}>Article</Button>
          </div>
        )
      },  
    },
  ]
  const articleColumns = [
    {table: "articles",value: "id", index:true, hide:true},
    {table: "articles",value: "uid", index:true, hide:true},
    {table: "articles",value: "keyword", index:true,},
    {table: "articles",value: "publishedDate", type:"date", index:true,  ignoreRowClick:true,},
    {table: "articles",value: "createdDate", type:"date", index:true, defaultOrder:"DESC", ignoreRowClick:true,},
  ]

  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"autoMessage",rightTable:"users",leftColumn:"userId",rightColumn:"id",},
  ]

  const saveKeywords = (keywords) => {
    var lines = keywords.split('\n');
    setLoading(true);
    request("keywords","/keywords","POST", {keywords:lines}, {
      then: function(res){
        setKeywords("");
        setRefreshInc(refreshInc + 1);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const editKeyword = (id,vol,lang) => {
    setEditId(0);
    setLoading(true);
    request("keywords","/keywords","PATCH", {id,vol,lang}, {
      then: function(res){setRefreshInc(refreshInc + 1);},
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const deleteKeyword = (id) => {
    request("keywords","/keywords","DELETE", {id}, {
      then: function(res){setRefreshInc(refreshInc + 1);},
      catch: function(err){ toast.error(err.message);},
      finally: function(){ }
    });
  }

  const refreshKeyword = (id,auto) => {
    let time = auto?10000:1;
    if(loadingRefreshId === id){
      return;
    }
    console.log("refreshKeyword",id);
    setTimeout(() => {
      console.log("refreshKeyword go",id);
      setLoadingRefreshId(id);
      request("keywords-"+id,"/keyword-refresh","POST", {id}, {
        then: function(res){setRefreshInc(refreshInc + parseInt(id));},
        catch: function(err){ toast.error(err.message);},
        finally: function(){setLoadingRefreshId(0);}
      });
    },time)
    
  }

  return (
    <div className="search-comp">
      <h3>Search Engine Optimizer</h3>

      <CarouselHeader slideTo={slide}>
        <div onClick={() => {setSlide(1)}}>Articles</div>
        <div onClick={() => {setSlide(2)}}>Key Words</div>
      </CarouselHeader>

      <Carousel slideTo={slide}>
        <div>
          <Table 
            displayName="Articles"
            name="ArticlesTable"
            database={"Search"}
            sideItems={sideItems}
            sideItemActiveClickFunc={(item) => {
              // console.log("item",item);
            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {
              // setShowUser(value);
              // setShowUserModal(true);
            }}
            refreshInc={refreshInc}
            columns={articleColumns}
            buttons={[
              { 
                status: "add",
                text:"New Article",
                onClick: () => {
                  setShow(true);
                }
              },
            ]}
            returnResultsFunc={(results) => {
              setResults(results);
            }}
            limit={10}
            />
        </div>
        <div>
          <div className='box'>
            <Textarea placeholder='Keywords...' value={keywords} onChange={(e) => {setKeywords(e.target.value)}}></Textarea>
            <Button status="add" onClick={() => {
              saveKeywords(keywords)
            }}>Add Keywords</Button>
          </div>
          <div className='line'></div>
          <Table 
            displayName="Keywords"
            name="KeywordsTable"
            database={"Search"}
            sideItems={sideItems}
            sideItemActiveClickFunc={(item) => {
              // console.log("item",item);
            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {
              // setShowUser(value);
              // setShowUserModal(true);
            }}
            refreshInc={refreshInc}
            columns={columns}
            buttons={[
              { 
                status: autoUpdateWords?"stop":"play",
                text:"Update Words",
                onClick: () => {
                  setAutoUpdateWords(!autoUpdateWords);
                }
              },
            ]}
            returnResultsFunc={(results) => {
              setResults(results);
            }}
            limit={100}
            />
        </div>
      </Carousel>


      <Modal show={show} handleClose={() => {setShow(false)}}>
        <div>
          <h3>Article Modal</h3>
          <form>
            <div>
              <label>Keyword</label>
              <input type="text" />
            </div>
            <div>
              <label>Type</label>
              <select>
                <option>List</option>
                <option>Paragraphs</option>
              </select>
            </div>
            <div>
              <label>Model</label>
              <select>
                <option>ChatGPT 3.5</option>
                <option>ChatGPT 4</option>
              </select>
            </div>
          </form>
          
        </div>
      </Modal>
      
    </div>
  );

};

export default Search;