const Languages = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
  { value: "zh", label: "Chinese" },
  { value: "fr", label: "French" },
  { value: "ar", label: "Arabic" },
  { value: "ru", label: "Russian" },
  { value: "pt", label: "Portuguese" },
  { value: "de", label: "German" },
  { value: "ja", label: "Japanese" },
  { value: "hi", label: "Hindi" },
  { value: "bn", label: "Bengali" },
  { value: "it", label: "Italian" },
  { value: "ko", label: "Korean" },
  { value: "tr", label: "Turkish" },
  { value: "id", label: "Indonesian" },
  { value: "nl", label: "Dutch" },
  { value: "pl", label: "Polish" },
  { value: "uk", label: "Ukrainian" },
  { value: "vi", label: "Vietnamese" },
  { value: "sv", label: "Swedish" },
  { value: "no", label: "Norwegian" },
  { value: "fi", label: "Finnish" },
  { value: "da", label: "Danish" },
  { value: "el", label: "Greek" },
  { value: "cs", label: "Czech" },
  { value: "hu", label: "Hungarian" },
  { value: "he", label: "Hebrew" },
  { value: "ro", label: "Romanian" },
  { value: "sk", label: "Slovak" },
  { value: "sl", label: "Slovenian" },
  { value: "th", label: "Thai" },
  { value: "hr", label: "Croatian" },
  { value: "sr", label: "Serbian" },
  { value: "fa", label: "Persian" },
  { value: "lt", label: "Lithuanian" },
  { value: "et", label: "Estonian" },
  { value: "lv", label: "Latvian" },
  { value: "bn", label: "Bengali" },
  { value: "ur", label: "Urdu" },
  { value: "hi", label: "Hindi" },
  { value: "mr", label: "Marathi" },
  { value: "ta", label: "Tamil" },
  { value: "te", label: "Telugu" },
  { value: "gu", label: "Gujarati" },
  { value: "kn", label: "Kannada" },
  { value: "ml", label: "Malayalam" },
  { value: "pa", label: "Punjabi" },
  { value: "sd", label: "Sindhi" },
  { value: "ne", label: "Nepali" },
  { value: "bg", label: "Bulgarian" },
  { value: "mk", label: "Macedonian" },
  { value: "bs", label: "Bosnian" },
  { value: "sq", label: "Albanian" },
  { value: "hy", label: "Armenian" },
  { value: "kk", label: "Kazakh" },
  { value: "uz", label: "Uzbek" },
  { value: "az", label: "Azerbaijani" },
  { value: "gl", label: "Galician" },
  { value: "eu", label: "Basque" },
  { value: "cy", label: "Welsh" },
  { value: "gd", label: "Scottish Gaelic" },
  { value: "ga", label: "Irish" },
  { value: "is", label: "Icelandic" },
  { value: "mt", label: "Maltese" },
  { value: "mi", label: "Maori" },
  { value: "sm", label: "Samoan" },
  { value: "to", label: "Tongan" },
  { value: "haw", label: "Hawaiian" },
  { value: "fj", label: "Fijian" },
  { value: "so", label: "Somali" },
  { value: "sw", label: "Swahili" },
  { value: "am", label: "Amharic" },
  { value: "ig", label: "Igbo" },
  { value: "yo", label: "Yoruba" },
  { value: "ha", label: "Hausa" },
  { value: "zu", label: "Zulu" },
  { value: "xh", label: "Xhosa" },
  { value: "st", label: "Southern Sotho" },
  { value: "tn", label: "Tswana" },
  { value: "ss", label: "Swati" },
  { value: "ve", label: "Venda" },
  { value: "nso", label: "Northern Sotho" },
  { value: "nr", label: "Ndebele" },
  { value: "ts", label: "Tsonga" },
  { value: "kr", label: "Kanuri" },
  { value: "dje", label: "Zarma" },
  { value: "ff", label: "Fulah" },
  { value: "kab", label: "Kabyle" },
  { value: "yo", label: "Yoruba" },
  { value: "qu", label: "Quechua" },
  { value: "ay", label: "Aymara" },
  { value: "gn", label: "Guarani" },

  // { value: "mapudungun", label: "Mapudungun" },
  // { value: "navajo", label: "Navajo" },
  // { value: "cherokee", label: "Cherokee" },
  // { value: "inuktitut", label: "Inuktitut" },
  // { value: "siksika", label: "Blackfoot" },
  // { value: "cheyenne", label: "Cheyenne" },
  // { value: "haida", label: "Haida" },
  // { value: "chamicuro", label: "Chamicuro" },
  // { value: "nuosu", label: "Yi" },
  // { value: "tamazight", label: "Tamazight" },
  // { value: "lakota", label: "Lakota" },
  // { value: "shoshoni", label: "Shoshoni" },
  // { value: "lenape", label: "Lenape" },
  // { value: "kalaallisut", label: "Greenlandic" },
  // { value: "inuinnaqtun", label: "Inuinnaqtun" },
  // { value: "klingon", label: "Klingon" },
  // { value: "na'vi", label: "Na'vi" },
  // { value: "papiamentu", label: "Papiamentu" },
  // { value: "tokipona", label: "Toki Pona" },
  // { value: "esperanto", label: "Esperanto" },
  // { value: "volapük", label: "Volapük" },
  // { value: "interlingua", label: "Interlingua" },
  // { value: "interlingue", label: "Interlingue" },
  // { value: "ido", label: "Ido" },
  // { value: "novial", label: "Novial" },
  // { value: "tsez", label: "Tsez" },
  // { value: "heptapod-b", label: "Heptapod B" },
  // { value: "vikingspeak", label: "Vikingspeak" },
  // { value: "sith", label: "Sith" },
  // { value: "valyrian", label: "Valyrian" },
  // { value: "dothraki", label: "Dothraki" },
  // { value: "highvalyrian", label: "High Valyrian" },
  // { value: "lowvalyrian", label: "Low Valyrian" },
  // { value: "jawaese", label: "Jawaese" },
  // { value: "minionese", label: "Minionese" },
  // { value: "kryptonian", label: "Kryptonian" },
  // { value: "gallifreyan", label: "Gallifreyan" },
  // { value: "dovahzul", label: "Dovahzul" },
  // { value: "elvish", label: "Elvish" },
  // { value: "gunganese", label: "Gunganese" },
  // { value: "huttese", label: "Huttese" },
  // { value: "nadsat", label: "Nadsat" },
  // { value: "newspeak", label: "Newspeak" },
  // { value: "orcish", label: "Orcish" },
  // { value: "sindarin", label: "Sindarin" },
  // { value: "sperethiel", label: "Sperethiel" },
  // { value: "tengwar", label: "Tengwar" },
  // { value: "trollish", label: "Trollish" },
  // { value: "twi'leki", label: "Twi'leki" },
  // { value: "vulcan", label: "Vulcan" },
  // { value: "wokis", label: "Wokis" },
  // { value: "xenolinguistics", label: "Xenolinguistics" },
  // { value: "yiddish", label: "Yiddish" },
  // { value: "zazaki", label: "Zazaki" }
];

export default Languages;
