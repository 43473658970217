import React from 'react';
import * as DateUtil from '../../../../utils/date';
import * as StringUtil from '../../../../utils/string';
import {UserSiteStatusIdEnum} from "../../../../enums/user-site-status";


const Profile = ({viewUser,load}) => {

  return (
    <div className="profile">
      {(!viewUser)?null:
        Object.keys(viewUser).map((key, i) => {
          let value = viewUser[key];

          if(key === "usersStatus"){
            value = UserSiteStatusIdEnum[value];
          }else if(key === "usersCreateDate" || key === "usersLastActive"){ 
            let timeSince = DateUtil.dateTimeSince(value,1,true);
            let prettyTime = DateUtil.prettifyDateWithTime(value);
            let epochTime = new Date(value).getTime();
            value = timeSince+" - "+prettyTime+" - "+epochTime
          }

          return (
            <div className='item' key={i}>
              <div>{StringUtil.prettifyCamelCase(key)}</div>
              <input type="text" disabled value={value}/>
            </div>
          )
        })
      }
    </div>
  );

};

export default Profile;